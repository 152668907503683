import React, { useState, useEffect } from "react";
import { graphql } from 'gatsby'
import { Container, Grid, Button, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import SEO from '../components/seo'
import PoloWrapper from '../components/PoloWrapper'
import { SectionTitle } from '../styles/pages/presencial'
import Layout from "../components/layout";

const useStyles = makeStyles(theme => ({
  imgSize: {
    width: '100vw',
    height: 500,
    backgroundSize: 'cover !important',
    backgroundRepeat: 'no-repeat'
  },
  select: {
    margin: '5px',
    padding: '8px 11px',
    color: '#404040',
    borderColor: '#404040',
    borderRadius: '20px',
    width: '50%',
    fontWeight: 'bold'
  },
  search: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  stateSelect: {
    display: 'flex',
    alignItems: 'center'
  },
  inputPolo: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  styledInput: {
    margin: '5px',
    padding: '7px',
    borderRadius: '20px',
    width: '70%',
    border: '1px solid #404040',
    color: '#404040',
    fontWeight: 'bold'
  },
  titleInput: {
    fontWeight: 'bold',
    marginTop: '20px'
  },
  btnFiltrar: {
    outline: 'none',
    border: 'none',
    background: '#ef4e45',
    color: '#fff',
    borderRadius: '20px',
    padding: '5 14px',
    fontWeight: 'bold',
    '&:hover': {
      background: '#ef4e45',
    },
    '&:focus': {
      outline: 'none',
      border: 'none'
    }
  }
}))
function handleChange(e) {
  this.setEstado({ value: e.target.value });
}
export default function MapPage(props) {
  const classes = useStyles()
  const { data } = props
  const { edges } = data.allMarkdownRemark
  const [estado, setEstado] = useState([])
  const [filteredCourses, setFilteredCourses] = useState(edges)
  const [query, setQuery] = useState('')
  const [searchResults, setSearchResults] = useState([])

  useEffect(() => {
    const posts = filteredCourses.filter(({ node }) =>
      node.frontmatter.name.toLowerCase().includes(query.toLowerCase())
    )
    setSearchResults(posts)
  }, [query.toLowerCase()])

  const filterCourses = filter => {
    if (filter) {
     return setSearchResults(
        edges.filter(({ node }) => node.frontmatter.state.includes(filter))
      );
    }
    return setSearchResults(
      edges.filter(({ node }) => node.frontmatter.state.includes(""))
    )
  }
  return (
    <Layout>
      <SEO title="Polos" />

      <Grid container spacing={2} style={{ marginTop: '20px' }}>
        <Grid item xs={12} lg={6}>
          <Grid container justify="center" alignItems="center" direction="row">
            <Typography variant="h5" className={classes.titleInput}>Pesquise o nome do polo</Typography>
          </Grid>
          <Grid className={classes.inputPolo}>
            <input
              placeholder="Pesquisar polo"
              value={query}
              onChange={e => setQuery(e.target.value)}
              className={classes.styledInput}
            // style={{ padding: 6, borderRadius: 20, width: '70%' }}
            />
          </Grid>

        </Grid>
        <Grid item xs={12} lg={6}>
          <Grid container justify="left" alignItems="center" direction="row">
            <Typography variant="h5" style={{ marginLeft: '10px' }} className={classes.titleInput}>Ou filtre por estado</Typography>
          </Grid>
          <Grid component="div" className={classes.stateSelect}>
            <select
              className={classes.select}
              onChange={e => setEstado(e.target.value)}
            >
              <option aria-label="None" value="" />
              <option value="AM">Amazonas</option>
              <option value="BA">Bahia</option>
              <option value="ES">Espírito Santo</option>
              <option value="GO">Goiáis</option>
              <option value="MA">Maranhão</option>
              <option value="PR">Paraná</option>
              {/* <option value="PE">Pernambuco</option> */}
              <option value="PI">Piauí</option>
              <option value="RO">Rondônia</option>
              <option value="SE">Sergipe</option>
              <option value="SP">São Paulo</option>

            </select>
            <Grid component="div">
              <Button variant="contained" className={classes.btnFiltrar} onClick={() => filterCourses(estado)}>Filtrar</Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>



      {/* <Grid container spacing={2} style={{ marginTop: 10 }}>
                <Grid container item xs={12} spacing={2}>
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                        <FilterButton
                            variant="contained"
                            startIcon={<Build />}
                            onClick={() => filterCourses('SE')}
                        >
                            Sergipe
                         </FilterButton>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                        <FilterButton
                            variant="contained"
                            startIcon={<Work />}
                            onClick={() => filterCourses('BA')}
                        >
                            Bahia
              </FilterButton>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                        <FilterButton
                            variant="contained"
                            startIcon={<Work />}
                            onClick={() => filterCourses('PE')}
                        >
                            Pernambuco
                     </FilterButton>
                    </Grid>
                </Grid>
            </Grid> */}
      <Container disableGutters={true} style={{ marginTop: 30 }}>
        <SectionTitle>Polos</SectionTitle>



        <Container>
          <Grid container spacing={3}>
            {searchResults.map(({ node }) => (
              <Grid xs={12} sm={12} md={6} lg={4} spacing={3}>
                <PoloWrapper
                  city={node.frontmatter.city}
                  address={node.frontmatter.address}
                  district={node.frontmatter.district}
                  name={node.frontmatter.name}
                  state={node.frontmatter.state}
                  link={node.fields.slug}
                />
              </Grid>


            ))}
          </Grid>
        </Container>
      </Container>
    </Layout>
  )
}
export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: { frontmatter: {category: {eq: "polo" } } }
    ) {
      edges {
        node {
          frontmatter {
            name
            state
            district
            city
            address
            category
           
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
